import {Component, OnDestroy, OnInit} from '@angular/core';
import {Router} from '@angular/router';
import {EMPTY, filter, Subject, switchMap} from 'rxjs';
import {AnnouncementService} from '../../core/service/announcement.service';
import {AuthService} from '../../core/service/auth.service';
import {OrganizationService} from '../../core/service/organization.service';
import {SupervisorService} from '../../core/service/supervisor.service';
import {AnnouncementAudience, AnnouncementStatus, IAnnouncement} from '../../shared/model/announcement.model';
import {IOpportunity, OpportunityStatus} from '../../shared/model/opportunity.model';
import {IOrganization, OrganizationStatus} from '../../shared/model/organization.model';
import {ISupervisor} from "../../shared/model/supervisor.model";
import {take} from "rxjs/operators";

@Component({
  selector: 'app-supervisor-landing-page',
  templateUrl: './supervisor-landing-page.component.html',
  styleUrls: ['./supervisor-landing-page.component.scss']
})
export class SupervisorLandingPageComponent implements OnInit, OnDestroy {
  private endSubscriptions$ = new Subject();

  //TODO: Update to be false once we have made the impersonate bar not always active
  public isImpersonating: boolean | undefined = true;
  public activeAnnouncements: IAnnouncement[] = [];
  public opportunities: IOpportunity[] = [];
  public organizations: IOrganization[] = [];


  constructor(
    private supervisorService: SupervisorService,
    private organizationService: OrganizationService,
    private authService: AuthService,
    public announcementService: AnnouncementService,
    private router: Router
  ) {}

  ngOnInit() {
    if (!this.router.url.includes('supervisor-contract')) {
      this.supervisorService.getSupervisedOpportunitiesFromCurrentUser().pipe(
        filter(Boolean),
        take(1),
        switchMap((opportunities: IOpportunity[]) => {
          this.opportunities = opportunities
            .filter(opportunity => opportunity.status == OpportunityStatus.ACTIVE);

          if (this.opportunities.length > 0) {
            this.router.navigate(['/', 'supervisor', 'my-opportunities', this.opportunities[0].id]);
            return EMPTY;
          } else {
            return this.supervisorService.getCurrentSupervisor().pipe(
              switchMap((supervisor: ISupervisor) => {
                return this.organizationService.getOrganizationsFromLeadId(supervisor.id);
              }),
              switchMap((organizations: IOrganization[]) => {
                this.organizations = organizations.filter(organization => organization.status === OrganizationStatus.ACTIVE);

                if (this.organizations.length > 0) {
                  this.router.navigate(['/', 'supervisor', 'my-organizations', this.organizations[0].id]);
                } else {
                  this.router.navigate(['/', 'supervisor', 'supervisor-no-organization']);
                }
                return EMPTY;
              })
            );
          }
        })
      ).subscribe()
    }

    this.getAnnouncementInformation();
  }

  ngOnDestroy() {
    this.endSubscriptions$.next(true);
    this.endSubscriptions$.complete();
  }

  private getAnnouncementInformation() {
    this.authService.isImpersonating().pipe(
      switchMap((isImpersonating: boolean | undefined) => {
        this.isImpersonating = isImpersonating ? isImpersonating : !isImpersonating;

        return this.announcementService.getAdminAnnouncements();
      }),
      switchMap((announcements: IAnnouncement[]) => {
        this.activeAnnouncements = announcements.filter(announcement => {
          return announcement.status === AnnouncementStatus.ACTIVE &&
            (announcement.audience === AnnouncementAudience.GLOBAL || announcement.audience === AnnouncementAudience.SUPERVISOR) &&
            new Date(announcement.startDate) < new Date() &&
            new Date(announcement.endDate) > new Date();
        });

        return EMPTY;
      })
    ).subscribe()
  }

  public calculatePageHeight() {
    const impersonateBarHeight = this.isImpersonating ? 48 : 0;
    const announcementBarHeight = this.activeAnnouncements.length > 0 ? 56 : 0;
    const maxHeight = `calc(100vh - ${impersonateBarHeight}px - ${announcementBarHeight}px)`;
    return `height: ${maxHeight}`;
  }
}
