import {Action, createReducer, on} from '@ngrx/store';
import {
  approvedOrganizationChangeSuccessAction,
  getMultipleOrganizationsAction,
  getMultipleOrganizationsFailureAction,
  getMultipleOrganizationsSuccessAction,
  getOrganizationAction,
  getOrganizationFailureAction,
  getOrganizationSuccessAction,
  resetSuccessfulUpdateAction,
  updateOrganizationAction,
  updateOrganizationFailureAction,
  updateOrganizationSuccessAction
} from './organization.actions';
import {IOrganizationState} from './organization.state';
import {stateHelperFunctions} from '../stateHelperFunctions';

export const initialOrganizationState: IOrganizationState = {
  organizations: [],
  loadingOrganizations: [],
  error: undefined,
  isUpdating: false,
  isUpdateSuccessful: false,
}

const organizationReducer = createReducer(
  initialOrganizationState,

  on(getOrganizationAction, (state, action): IOrganizationState => {
      return stateHelperFunctions.hasItem(state, action.id) ? state : ({
        ...state,
        loadingOrganizations: state.loadingOrganizations.concat([action.id])
      })
    }
  ),

  on(updateOrganizationAction, (state, action): IOrganizationState => {
      return stateHelperFunctions.hasItem(state, action.data.id)
        ? {
          ...state,
          isUpdating: true,
          isUpdateSuccessful: false,
        }
        : ({
        ...state,
          isUpdating: true,
          isUpdateSuccessful: false,
        loadingOrganizations: state.loadingOrganizations.concat([action.data.id])
      })
    }
  ),

  on(getOrganizationSuccessAction, (state, action): IOrganizationState => ({
      ...state,
      loadingOrganizations: stateHelperFunctions.removeLoadingId(state.loadingOrganizations, action.data.id),
      organizations: state.organizations?.concat(action.data)
    })
  ),

  on(getOrganizationFailureAction,
    updateOrganizationFailureAction,
    (state, action): IOrganizationState => ({
      ...state,
      isUpdating: false,
      isUpdateSuccessful: false,
      loadingOrganizations: stateHelperFunctions.removeLoadingId(state.loadingOrganizations, action.id),
      error: action.error.error
    })
  ),

  on(getMultipleOrganizationsAction, (state, action): IOrganizationState => {
      return stateHelperFunctions.hasAllItems(state, action.ids) ? state : ({
        ...state,
        loadingOrganizations: state.loadingOrganizations.concat(stateHelperFunctions.getMissingIds(state, action.ids))
      })
    }
  ),

  on(getMultipleOrganizationsSuccessAction, (state, action): IOrganizationState => ({
      ...state,
      loadingOrganizations: stateHelperFunctions.removeLoadingIds(state.loadingOrganizations, action.ids),
      organizations: state.organizations?.concat(action.data)
    })
  ),

  on(getMultipleOrganizationsFailureAction, (state, action): IOrganizationState => ({
      ...state,
      loadingOrganizations: stateHelperFunctions.removeLoadingIds(state.loadingOrganizations, action.ids),
      error: action.error.error
    })
  ),

  on(updateOrganizationSuccessAction, (state, action): IOrganizationState => ({
      ...state,
      isUpdating: false,
      isUpdateSuccessful: true,
      loadingOrganizations: stateHelperFunctions.removeLoadingId(state.loadingOrganizations, action.data.id),
      organizations: ('organizationChanges' in action.data) ? state.organizations : stateHelperFunctions.replaceItemInArray(state.organizations, action.data),
      error: undefined
    })
  ),

  on(resetSuccessfulUpdateAction, (state, action): IOrganizationState => ({
    ...state,
    isUpdateSuccessful: false
  })),

  on(approvedOrganizationChangeSuccessAction, (state, action): IOrganizationState => ({
      ...state,
      organizations: stateHelperFunctions.replaceItemInArray(state.organizations, action.data),
      error: undefined
    })
  ),
);

export function organizationReducers(state: IOrganizationState, action: Action) {
  return organizationReducer(state, action);
}
