import {HttpErrorResponse} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import {concatLatestFrom} from '@ngrx/operators';
import {select, Store} from '@ngrx/store';
import {exhaustMap, filter, map, mergeMap, of, switchMap, take, tap} from 'rxjs';
import {catchError} from 'rxjs/operators';
import {OpportunityRepository} from '../../../repository/opportunity.repository';
import {MessageService} from '../../../service/message.service';
import {
  getMultipleOrganizationsAction,
  getOrganizationAction,
  updateOrganizationSuccessAction
} from '../../organization/organization.actions';
import {getSupervisorAction} from '../../supervisor/supervisor.actions';
import {
  getOpportunityAction,
  getOpportunityFailureAction,
  getOpportunitySuccessAction,
  searchOpportunitiesAction,
  searchOpportunitiesFailureAction,
  updateOpportunityAction,
  updateOpportunityFailureAction,
  updateOpportunitySuccessAction
} from '../opportunity.actions';
import {getOpportunitySelector, isLoadingDuplicateOpportunitySelector,} from '../opportunity.selectors';
import {SupervisorRepository} from "../../../repository/supervisor.repository";
import {OrganizationRepository} from "../../../repository/organization.repository";

@Injectable()
export class GetOpportunityEffect {
  constructor(private actions$: Actions,
              private store: Store,
              private oppRepo: OpportunityRepository,
              private supervisorRepo: SupervisorRepository,
              private messageService: MessageService,
              private orgRepo: OrganizationRepository,
  ) {
  }

  // noinspection TypeScriptValidateTypes
  getOpportunity$ = createEffect(() => this.actions$.pipe(
    ofType(getOpportunityAction),
    concatLatestFrom((action) => this.store.pipe(select(isLoadingDuplicateOpportunitySelector(action.id)))),
    filter(([, isDuplicate]) => !isDuplicate),
    mergeMap(([action,]) => {
      return this.oppRepo.getOpportunity(action.id).pipe(
        map(response => getOpportunitySuccessAction({data: response})),
        catchError((errorResponse: HttpErrorResponse) => of(getOpportunityFailureAction({
          error: errorResponse,
          id: action.id
        })))
      );
    })
  ));

  // noinspection TypeScriptValidateTypes
  getOpportunityOrganization$ = createEffect(() => this.actions$.pipe(
    ofType(getOpportunitySuccessAction),
    switchMap((response) => of(getOrganizationAction({id: response.data.organizationId})))
  ));

  // noinspection TypeScriptValidateTypes
  getOpportunityLeadSupervisor$ = createEffect(() => this.actions$.pipe(
    ofType(getOpportunitySuccessAction),
    switchMap((response) => of(getSupervisorAction({id: response.data.supervisorId})))
  ));

  // noinspection TypeScriptValidateTypes
  searchOpportunities$ = createEffect(() => this.actions$.pipe(
    ofType(searchOpportunitiesAction),
    exhaustMap((action) => {
      return this.oppRepo.findOpportunities(action.searchFilter).pipe(
        map(response => getMultipleOrganizationsAction({
          ids: [...new Set(response.map(opp => opp.organizationId))],
          opportunities: response
        })),
        catchError((errorResponse: HttpErrorResponse) => of(searchOpportunitiesFailureAction({error: errorResponse})))
      )
    })
  ));

  // noinspection TypeScriptValidateTypes
  updateOpportunity$ = createEffect(() => this.actions$.pipe(
    ofType(updateOpportunityAction),
    concatLatestFrom((action) => this.store.select(getOpportunitySelector(action.data.id))),
    mergeMap(([action, prevOpp]) => {
      return this.oppRepo.updateOpportunity(action.data).pipe(
        map(response => updateOpportunitySuccessAction({
          data: response,
          isLeadChanging: !prevOpp || action.data.supervisorId != prevOpp.supervisorId
        })),
        catchError((errorResponse: HttpErrorResponse) => of(updateOpportunityFailureAction({
          error: errorResponse,
          id: action.data.id
        })))
      )
    })
  ))

  // noinspection TypeScriptValidateTypes
  updateOpportunitySuccess$ = createEffect(() => this.actions$.pipe(
    ofType(updateOpportunitySuccessAction),
    tap((response) => {
      this.messageService.showMessage("Opportunity updated successfully.")

      // Fetch the organization and update the organization state since opportunity updates can change organization data
      this.orgRepo.getOrganization(response.data.organizationId).pipe(take(1)).subscribe((organization) => {
        this.store.dispatch(updateOrganizationSuccessAction({ data: organization, isLeadChanging: false }));
      });

      // if supervisorID != currentSupervisor => reload
      this.supervisorRepo.getCurrentSupervisor().pipe(take(1)).subscribe((supervisor) => {
        if (response.isLeadChanging && response.data.supervisorId !== supervisor.id) {
          window.location.reload();
        }
      })
    })
  ), {dispatch: false})

  // noinspection TypeScriptValidateTypes
  updateOpportunityFailure$ = createEffect(() => this.actions$.pipe(
    ofType(updateOpportunityFailureAction),
    tap((action) => {
      if (action?.error?.error?.error?.includes("cannot be assigned")) {
        this.messageService.showErrorMessage(action?.error?.error?.error);
      } else {
        this.messageService.showErrorMessage("Unable to update opportunity at this time. Please try again later.")
      }
    })
  ), {dispatch: false})

}
