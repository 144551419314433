<div class="title">
  <div class="large-bold-text">Global Settings</div>
</div>
<div class="settings f-row">
  <div class="labels">
    <div class="small-reg-text">Max Volunteers per Opportunity</div>
    <div class="x-small-italic">Updating this will not effect opportunities that will then become over the limit</div>
  </div>
  <input class="custom-input supervisors-input" matInput placeholder="{{currentMaxVolunteerCount | async}}" [disabled]="!isEditingLimit"
         type="number" inputmode="numeric" data-cy="maxSupervisors" [(ngModel)]="updatedMaxVolunteerCount">
  @if(!isEditingLimit) {
    <div class="icon-wrapper" (click)="switchEditingView()">
      <app-icon icon="edit" class="edit-icon large-icon"></app-icon>
    </div>
  } @else {
    <button class="custom-button-large-primary-dark-blue" (click)="save()">Save</button>
    <button class="custom-button-large-secondary" (click)="switchEditingView()">Cancel</button>
  }

</div>


