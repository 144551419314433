import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {AdminSettingsComponent} from './feature/admin-settings/admin-settings.component';
import {UploadsComponent} from './feature/uploads/uploads.component';
import {LandingPageComponent} from './landing-page/landing-page.component';
import {StudentLandingPageComponent} from './landing-page/student-landing-page/student-landing-page.component';
import {studentGuardFn} from './routing-hub/guards/student.guard';
import {SupervisorLandingPageComponent} from "./landing-page/supervisor-landing-page/supervisor-landing-page.component";
import {AdminLandingPageComponent} from "./landing-page/admin-landing-page/admin-landing-page.component";
import {supervisorGuardFn} from "./routing-hub/guards/supervisor.guard";
import {adminGuardFn} from "./routing-hub/guards/admin.guard";
import {FormsModule} from "@angular/forms";
import {incomingImpersonateGuardFn} from "./routing-hub/guards/incoming-impersonate.guard";
import {accessRevokedGuardFn} from "./routing-hub/guards/access-revoked.guard";
import {AccessRevokedComponent} from "./feature/access-revoked/access-revoked.component";

const routes: Routes = [
  {
    path: '',
    component: LandingPageComponent,
    canActivate: [accessRevokedGuardFn],
    children: [
      {
        path: 'access-revoked',
        component: AccessRevokedComponent,
        pathMatch: 'full',
      },
      {
        path: 'impersonate',
        canActivate: [incomingImpersonateGuardFn],
        pathMatch: 'full',
        children: []
      },
      {
        path: '',
        component: StudentLandingPageComponent,
        canActivate: [studentGuardFn],
        // canActivate: [incomingImpersonateGuardFn, studentGuardFn],
        children: [
          {
            path: '',
            redirectTo: 'student-dashboard',
            pathMatch: 'full'
          },
          {
            path: 'student-dashboard',
            canActivate: [studentGuardFn],
            loadChildren: () => import('./feature/student-dashboard/student-dashboard.module').then(m => m.StudentDashboardModule),
          },
          {
            path: 'student-history',
            loadChildren: () => import('./feature/student-history/student-history.module').then(m => m.StudentHistoryModule),
          },
          {
            path: 'opportunity',
            loadChildren: () => import('./feature/opportunity/opportunity.module').then(m => m.OpportunityModule),
          },
          {
            path: 'organization',
            loadChildren: () => import('./feature/organization/organization.module').then(m => m.OrganizationModule),
          },
          {
            path: 'search-opportunity',
            loadChildren: () => import('./feature/search-opportunity/search-opportunity.module').then(m => m.SearchOpportunityModule),
          },
          {
            path: 'register-opportunity/:opportunityId',
            loadChildren: () => import('./feature/register-opportunity/register-opportunity.module').then(m => m.RegisterOpportunityModule),
          },
          {
            path: 'evaluation',
            loadChildren: () => import('./feature/evaluation/evaluation.module').then(m => m.EvaluationModule)
          },
          {
            path: 'request-confirmation',
            loadChildren: () => import('./shared/components/request-confirmation/request-confirmation.module').then(m => m.RequestConfirmationModule)
          },
          {
            path: 'audit',
            loadChildren: () => import('./feature/audit/audit.module').then(m => m.AuditModule)
          }
        ]
      },
      {
        path: 'supervisor',
        component: SupervisorLandingPageComponent,
        canActivate: [supervisorGuardFn],
        children: [
          {
            path: 'my-opportunities/:opportunityId',
            loadChildren: () => import('./feature/opportunity-config/opportunity-config.module').then(m => m.OpportunityConfigModule),
          },
          {
            path: 'edit-profile',
            loadChildren: () => import('./feature/edit-profile/edit-profile-module').then(m => m.EditProfileModule),
          },
          {
            path: 'my-organizations/:organizationId',
            loadChildren: () => import('./feature/organization-config/organization-config.module').then(m => m.OrganizationConfigModule)
          },
          {
            path: 'organization',
            loadChildren: () => import('./feature/organization/organization.module').then(m => m.OrganizationModule),
          },
          {
            path: 'opportunity',
            loadChildren: () => import('./feature/opportunity/opportunity.module').then(m => m.OpportunityModule),
          },
          {
            path: 'opportunity-confirmation/:organizationId',
            loadChildren: () => import('./shared/components/request-confirmation/request-confirmation.module').then(m => m.RequestConfirmationModule)
          },
          {
            path: 'opportunity-confirmation/edit/:opportunityId',
            loadChildren: () => import('./shared/components/request-confirmation/request-confirmation.module').then(m => m.RequestConfirmationModule)
          },
          {
            path: 'past-opportunities',
            loadChildren: () => import('./feature/past-opportunities/past-opportunities.module').then(m => m.PastOpportunitiesModule),
          },
          {
            path: 'service-evaluation',
            loadChildren: () => import('./feature/evaluation/evaluation.module').then(m => m.EvaluationModule)
          },
          {
            path: 'evaluation-confirmation',
            loadChildren: () => import('./shared/components/request-confirmation/request-confirmation.module').then(m => m.RequestConfirmationModule)
          },
          {
            path: 'evaluation-correction',
            loadChildren: () => import('./shared/components/request-confirmation/request-confirmation.module').then(m => m.RequestConfirmationModule)
          },
          {
            path: 'supervisor-no-organization',
            loadChildren: () => import('./feature/supervisor-no-org/supervisor-no-org.module').then(m => m.SupervisorNoOrgModule),
          },
          {
            path: 'supervisor-contract',
            loadChildren: () => import('./feature/supervisor-contract/supervisor-contract.module').then(m => m.SupervisorContractModule),
          },
          {
            path: 'contract-submitted',
            loadChildren: () => import('./shared/components/request-confirmation/request-confirmation.module').then(m => m.RequestConfirmationModule)
          },
          {
            path: 'rollover/:organizationId',
            loadChildren: () => import('./feature/opportunity-rollover/opportunity-rollover.module').then(m => m.OpportunityRolloverModule)
          }
        ],
      },
      // {
      //   path: 'supervisor-contract', component: SupervisorContractComponent,
      // },
      {
        path: 'admin',
        component: AdminLandingPageComponent,
        canActivate: [adminGuardFn],
        children: [
          {
            path: '',
            redirectTo: 'users',
            pathMatch: 'full'
          },
          {
            path: 'organizations',
            loadChildren: () => import('./feature/organization-admin/organization-admin.module').then(m => m.OrganizationAdminModule),
          },
          {
            path: 'supervisors',
            loadChildren: () => import('./feature/supervisor-admin/supervisor-admin.module').then(m => m.SupervisorAdminModule),
          },
          {
            path: 'placements',
            loadChildren: () => import('./feature/placement-admin/placement-admin.module').then(m => m.PlacementAdminModule),
          },
          {
            path: 'users',
            loadChildren: () => import('./feature/user-admin/user-admin.module').then(m => m.UserAdminModule),
          },
          {
            path: 'edit-profile',
            loadChildren: () => import('./feature/edit-profile/edit-profile-module').then(m => m.EditProfileModule),
          },
          {
            path: 'my-organizations/:id',
            loadChildren: () => import('./feature/organization-config/organization-config.module').then(m => m.OrganizationConfigModule)
          },
          {
            path: 'organization',
            loadChildren: () => import('./feature/organization/organization.module').then(m => m.OrganizationModule),
          },
          {
            path: 'opportunity',
            loadChildren: () => import('./feature/opportunity/opportunity.module').then(m => m.OpportunityModule),
          },
          {
            path: 'approvals',
            loadChildren: () => import('./feature/approvals-admin/approvals.admin.module').then(m => m.ApprovalsAdminModule),
          },
          {
            path: 'announcements',
            loadChildren: () => import('./feature/announcements-admin/announcements-admin.module').then(m => m.AnnouncementsAdminModule),
          },
          {
            path: 'terms',
            loadChildren: () => import('./feature/terms-admin/terms-admin.module').then(m => m.TermsAdminModule),
          },
          {
            path: 'uploads',
            component: UploadsComponent
          },
          {
            path: 'settings',
            component: AdminSettingsComponent
          }
        ],
      }
    ]
  },
  {
    path: '**',
    redirectTo: ''
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {}), FormsModule
  ],
  exports: [RouterModule]
})
export class AppRoutingModule {
}
