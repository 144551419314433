import {IOpportunityConfigState} from "./opportunity-config.state";
import {Action, createReducer, on} from "@ngrx/store";
import {
  addRegistrationRequestRecordAction,
  addSupervisorEvalPendingRecordAction, addSupervisorRecordAction,
  addVolunteerRecordAction,
  clearOpportunityDataAction,
  removeRegistrationRequestRecordAction,
  removeSupervisorEvalPendingRecordAction, removeSupervisorRecordAction,
  removeVolunteerRecordAction,
  setCurrentSupervisorAction,
  setIsLeadSupervisorAction,
  setOpportunityDataAction, updateRegistrationRequestRecordAction, updateVolunteerRecordAction
} from './opportunity-config.actions';
import {stateHelperFunctions} from "../stateHelperFunctions";

export const initialOpportunityConfigState: IOpportunityConfigState = {
  opportunityData: undefined,
  volunteerRecords: [],
  supervisorRecords: [],
  registrationRequestCount: 0,
  registrationRequestRecords: [],
  supervisorEvalPendingCount: 0,
  supervisorEvalPendingRecords: [],
  maxVolunteers: 0,
  currentSupervisor: undefined,
  isLead: false
};

const opportunityConfigReducer = createReducer (
  initialOpportunityConfigState,

  on (setCurrentSupervisorAction,
    (state, action): IOpportunityConfigState => ({
      ...state,
      currentSupervisor: action.supervisor,
    })
  ),

  on (setIsLeadSupervisorAction,
    (state, action): IOpportunityConfigState => ({
      ...state,
      isLead: action.isLead,
    })
  ),

  on (addRegistrationRequestRecordAction,
    (state, action): IOpportunityConfigState => ({
      ...state,
      registrationRequestRecords: state.registrationRequestRecords.concat([action.record]),
    })
  ),

  on (updateRegistrationRequestRecordAction,
    (state, action): IOpportunityConfigState => ({
      ...state,
      registrationRequestRecords: stateHelperFunctions.replaceItemInArray(state.registrationRequestRecords, action.record),
    })
  ),

  on (removeRegistrationRequestRecordAction,
    (state, action): IOpportunityConfigState => ({
      ...state,
      registrationRequestRecords: stateHelperFunctions.removeItemInArray(state.registrationRequestRecords, action.record),
    })
  ),

  on (addSupervisorEvalPendingRecordAction,
    (state, action): IOpportunityConfigState => ({
      ...state,
      supervisorEvalPendingRecords: state.supervisorEvalPendingRecords.concat([action.record]),
    })
  ),

  on (removeSupervisorEvalPendingRecordAction,
    (state, action): IOpportunityConfigState => ({
      ...state,
      supervisorEvalPendingRecords: stateHelperFunctions.removeItemInArray(state.supervisorEvalPendingRecords, action.record),
    })
  ),

  on (addVolunteerRecordAction,
    (state, action): IOpportunityConfigState => ({
      ...state,
      volunteerRecords: state.volunteerRecords.concat([action.record]),
    })
  ),

  on (updateVolunteerRecordAction,
    (state, action): IOpportunityConfigState => ({
      ...state,
      volunteerRecords: stateHelperFunctions.replaceItemInArray(state.volunteerRecords, action.record),
    })
  ),

  on (removeVolunteerRecordAction,
    (state, action): IOpportunityConfigState => ({
      ...state,
      volunteerRecords: stateHelperFunctions.removeItemInArray(state.volunteerRecords, action.record),
    })
  ),

  on (addSupervisorRecordAction,
    (state, action): IOpportunityConfigState => ({
      ...state,
      supervisorRecords: state.supervisorRecords.concat([action.record]),
    })
  ),

  on (removeSupervisorRecordAction,
    (state, action): IOpportunityConfigState => ({
      ...state,
      supervisorRecords: stateHelperFunctions.removeItemInArray(state.supervisorRecords, action.record),
    })
  ),

  on (setOpportunityDataAction,
    (state, action): IOpportunityConfigState => ({
      ...state,
      opportunityData: action.opportunity,
      maxVolunteers: Number(action.opportunity.maxStudents)
    })
  ),

  on (clearOpportunityDataAction,
    (): IOpportunityConfigState => ({
      ...initialOpportunityConfigState
    })
  )
);

export function opportunityConfigReducers(state: IOpportunityConfigState, action: Action) {
  return opportunityConfigReducer(state, action);
}

