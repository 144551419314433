import { Component, OnDestroy, OnInit } from '@angular/core';
import { setStyle } from '../../shared/function/form-validators';
import { GlobalsService } from '../../core/service/globals.service'
import { Observable, Subject, takeUntil } from 'rxjs';

@Component({
  selector: 'app-admin-settings',
  templateUrl: './admin-settings.component.html',
  styleUrls: ['./admin-settings.component.scss']
})
export class AdminSettingsComponent implements OnInit, OnDestroy {
  public isEditingLimit: boolean = false;
  public currentMaxVolunteerCount!: Observable<number>;
  public updatedMaxVolunteerCount: number = 50;
  private onDestroy$: Subject<void> = new Subject<void>();

  protected readonly setStyle = setStyle;

  constructor(private globalsService: GlobalsService) { }

  ngOnInit(): void {
    this.initializeCounts();
  }

  public switchEditingView() {
    this.isEditingLimit = !this.isEditingLimit;
    this.initializeCounts();
  }

  public save() {
    this.globalsService.setMaxVolunteerCount(this.updatedMaxVolunteerCount.toString()).pipe(takeUntil(this.onDestroy$)).subscribe();
    this.switchEditingView();
  }

  private initializeCounts() {
    this.currentMaxVolunteerCount = this.globalsService.getMaxVolunteerCount();
    this.currentMaxVolunteerCount.pipe(takeUntil(this.onDestroy$)).subscribe((count) => {
      this.updatedMaxVolunteerCount = count;
    });
  }

  ngOnDestroy() {
    this.onDestroy$.next();
    this.onDestroy$.complete();
  }

}
